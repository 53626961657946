/**
 * Name: New Playlist Page
 * Author: @Gaurav
 * Date: 19-07-21
 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import RoundButton from '../../components/RoundButton';
import '../NewPlaylist/NewPlaylist.scss';
import { _showAlert } from '../../utils';
import { LoaderSpinner } from '../../components';

function NewPlaylist() {
  const [playlistName, setPlaylistName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const newPlaylistRequestData = useSelector((state) => state.newPlaylist.result);

  /**
   * it will handle the create playlist api request
   */

  const handleCreatePlaylist = () => {
    setLoading(true);
    dispatch({ type: 'NEW_PLAYLIST', payload: { name: playlistName, songs: [] } });
  };

  useEffect(() => {
    if (newPlaylistRequestData && newPlaylistRequestData.id) {
      setLoading(false);
      _showAlert('Playlist has been created successfully.', 'success');
      history.push('/edit-playlist-main');
    }
    if (newPlaylistRequestData.error) {
      setLoading(false);
      _showAlert(newPlaylistRequestData.error, 'error', 10000);
    }
  }, [newPlaylistRequestData]);

  useEffect(() => {
    // clear the state of new playlist while closing the component
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'NEW_PLAYLIST' });
    };
  }, []);

  return (
    <div className="main empty_page">
      {isLoading && <LoaderSpinner />}
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="leftPortion">
        <NavBar data="New Playlist" icon={Settings} currentRouteName="Playlists" breadcrumbsPath="New Playlist" />
        <div className="content empty_row">
          <div className="create_playlist_row">
            <input placeholder="Playlist Name" type="text" onChange={(e) => setPlaylistName(e.target.value)} />
            <RoundButton btnText="Create Playlist" handleClick={handleCreatePlaylist} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPlaylist;
