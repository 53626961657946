import fetch from 'cross-fetch';
import { _isAuthenticated, _refreshToken, _showAlert } from '../utils';

// check http status code and throw error or exception
const checkStatus = (response) => {
  console.log('==== API response ==== ', response);
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 404) {
    return {
      code: response.status,
      error: 'Service Not Found',
    };
  }
  if (response.status === 500) {
    return {
      code: response.status,
      error: '500 Internal Server Error',
    };
  }
  if (response.status === 403) {
    _refreshToken();
    _showAlert('Please wait.. Regenerating access token', 'error', 10000);
    return {
      code: response.status,
      error: 'Authorizaton Error: Session time out please login again',
    };
  }
  return {
    code: response.status,
    error: 'Something went wrong on service request or data provided by you. Please chcek your network and system.',
  };
};

// parse response data
const parseJSON = (response) => {
  if (response.status >= 200 && response.status < 300) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json();
    }
  }
  return response;
};

const _getHeader = () => {
  const header = {
    'Content-Type': 'application/json',
  };
  const { tokens } = _isAuthenticated();
  if (tokens) {
    header['authorization'] = tokens?.accessToken;
    header['Content-Type'] = 'application/json';
    return header;
  }
  return header;
};

// Make an api call
export default (endpoint, method = 'post', body, headers) => {
  return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    headers: headers || _getHeader(),
    method,
    body: body ? (headers ? body : JSON.stringify(body)) : null,
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      console.log('==== API catch error ==== ', error);
      throw {
        code: 504,
        error: 'Something went wrong on service request or data provided by you. Please chcek your network and system.',
      };
    });
};
