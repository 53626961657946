import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import '../CustomerList/CustomerList.scss';
import ApiCaller from '../../redux/ApiCaller';
import { _showAlert } from '../../utils';

const MONTHS = ['Jan', 'Fec', 'March', 'April', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const LogsModal = ({ setLogsModalOpen, selectedCustomer }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await ApiCaller(`/v1/admin/customer/${selectedCustomer.id}/logs`, 'GET');
        setLogs(data.logs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        _showAlert(error.message, 'error');
      }
    })();
  }, [selectedCustomer]);

  const converToTwoDigit = (number) => {
    if (parseInt(number) < 10) return `0${number}`;
    return number;
  };

  const getDateAndTime = (obj) => {
    console.log(obj);
    const jsDate = new Date(obj);
    console.log(jsDate);
    const year = jsDate.getFullYear();
    const month = MONTHS[jsDate.getMonth()];
    const date = jsDate.getDate();
    let hour = jsDate.getHours();
    if (hour === 0) hour = 12;
    const minutes = jsDate.getMinutes();
    return {
      date: `${date} ${month} ${year}`,
      time: `${converToTwoDigit(hour)}:${converToTwoDigit(minutes)}`,
    };
  };

  return (
    <div className="modalBackDrop" onClick={() => setLogsModalOpen(false)}>
      <div className="logsModal" onClick={(e) => e.stopPropagation()}>
        <p className="header">Logs</p>
        {loading ? (
          <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div style={{ fontWeight: '500', color: '#000', paddingBottom: '7px' }} className="row">
              <p className="actions">Actions</p>
              <p className="date">Date</p>
              <p className="time">Time</p>
            </div>
            <div className="contentContainer">
              {logs
                .sort((a, b) => new Date(b.time) - new Date(a.time))
                .map((item, index) => {
                  const { action, time: backendTime } = item;
                  const { date, time } = getDateAndTime(backendTime);
                  return (
                    <div key={index} className="row">
                      <p className="actions">{action}</p>
                      <p className="date">{date}</p>
                      <p className="time">{time}</p>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LogsModal;

