/**
 * Name: AddSongs_2
 * Author: @Gaurav
 * Date: 09-21-2020
 */
import React from 'react';
import '../AddSong/Addsong.scss';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import RoundButton from '../../components/RoundButton';

/* 
Table Data and Use Style
*/
const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderRadius: 30,
  },
});

function SelectedSongsListSection({ onUploadClick, songs }) {
  const classes = useStyles();
  return (
    <>
      <div className="btn_row">
        <RoundButton handleClick={onUploadClick} btnText="Upload" />
      </div>
      <TableContainer className="tbl_wraper add-2" component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="table_font">Result</TableCell>

              <TableCell>Song Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(songs).map((song, index) => (
              <TableRow key={index}>
                <TableCell style={{ fontWeight: 'bold', borderBottom: 'none', paddingLeft: '34px' }}>{`# ${++index}`}</TableCell>
                <TableCell style={{ borderBottom: 'none' }}>{song.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default SelectedSongsListSection;
