/**
 * Name: Saga Container
 * Author: @Gaurav
 * Date: 19-07-2021
 */
import { all } from 'redux-saga/effects';
import login from './Login';
import clearState from './ClearState';
import addNewSongs from './AddNewSongs';
import newPlaylist from './NewPlaylist';
import newCustomer from './NewCustomer';
import customerList from './CustomerList';
import searchSongs from './SearchSongs';
import deleteSongs from './DeleteSongs';
import getAllPlaylist from './GetAllPlayList';
import deletePlaylist from './DeletePlaylist';
import updatePlaylist from './UpdatePlaylist';
import deleteCustomerPlaylist from './DeleteCustomerPlaylist';
import getCustomerPlaylist from './GetCustomerPlaylist';
import addCustomerPlaylist from './AddCustomerPlaylist';
import getSongURL from './GetSongURL';
import scheduleCustomerSong from './ScheduleCustomerSong';
import deleteCustomerSchedule from './DeleteCustomerSchedule';

export default function* rootSaga() {
  yield all([
    login(),
    clearState(),
    addNewSongs(),
    newPlaylist(),
    newCustomer(),
    customerList(),
    searchSongs(),
    deleteSongs(),
    getAllPlaylist(),
    deletePlaylist(),
    updatePlaylist(),
    deleteCustomerPlaylist(),
    getCustomerPlaylist(),
    addCustomerPlaylist(),
    getSongURL(),
    scheduleCustomerSong(),
    deleteCustomerSchedule(),
  ]);
}
