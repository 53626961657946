import * as React from 'react';
const RefreshIconSvg = (props) => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.125 17.9999C25.1255 19.5463 24.5951 21.046 23.6224 22.2481C22.6497 23.4503 21.2937 24.282 19.7813 24.6041C18.2688 24.9263 16.6916 24.7194 15.3134 24.018C13.9353 23.3166 12.8397 22.1633 12.21 20.7509L10.1235 21.5984C10.9506 23.4936 12.405 25.0462 14.2422 25.9953C16.0794 26.9443 18.1873 27.2319 20.2116 26.8096C22.2358 26.3874 24.053 25.2811 25.3575 23.6767C26.6621 22.0724 27.3745 20.0678 27.375 17.9999C27.3751 16.1215 26.7874 14.2902 25.6944 12.7625C24.6014 11.2349 23.0578 10.0875 21.2799 9.48122C19.5021 8.87492 17.5791 8.8401 15.7804 9.38162C13.9818 9.92314 12.3976 11.0139 11.25 12.5009V9.74993H9V15.7499L10.125 16.8749H15.375V14.6249H12.528C13.2707 13.3374 14.4175 12.3311 15.7906 11.762C17.1636 11.1929 18.6862 11.0929 20.1219 11.4776C21.5576 11.8622 22.8261 12.71 23.7308 13.8893C24.6354 15.0687 25.1255 16.5136 25.125 17.9999V17.9999Z"
      fill="black"
    />
  </svg>
);
export default RefreshIconSvg;

