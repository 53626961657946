/**
 * Name: New Customer Reducer
 * Author: @Gaurav
 * Date: 19-07-2021
 */
export default function reducer(
  state = {
    status: 'uninitiated',
    result: {},
  },
  action
) {
  switch (action.type) {
    case 'NEW_CUSTOMER_INITIATED': {
      return { ...state, status: 'initiated' };
    }
    case 'NEW_CUSTOMER_SUCCESS': {
      return { ...state, status: 'success', result: action.payload };
    }
    case 'NEW_CUSTOMER_FAILED': {
      return { ...state, status: 'failed', result: action.payload };
    }
    default: {
      return state;
    }
  }
}
