import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import RoundButton from '../../components/RoundButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../CustomerDetails/CustomerDetails.scss';
import { _showAlert, formatDateToDisplay, getShortNamesOfWeekDays, getSchedulesFromPlaylist } from '../../utils';

const useStyles = makeStyles({
  table: {
    maxHeight: '90vh',
    borderRadius: 30,
  },
});

function CustomerDetails(props) {
  const classes = useStyles();
  const playlistAndScheduler = useSelector((state) => state.getCustomerPlaylist.result);
  const dispatch = useDispatch();
  const [selectedCustomer, setSelectedCustomer] = useState({});

  useEffect(() => {
    if (props?.history?.location?.state) {
      setSelectedCustomer(props.history.location.state);
    }
  }, [props.history]);

  useEffect(() => {
    if (selectedCustomer.id) {
      dispatch({ type: 'GET_CUSTOMER_PLAYLIST', payload: { customerId: selectedCustomer.id } });
    }
  }, [selectedCustomer]);

  const handleEditCustomerPlaylist = () => {
    props.history.push({
      pathname: '/edit-customer-playlists',
      state: selectedCustomer,
    });
  };

  const handleEditCustomerScheduler = () => {
    props.history.push({
      pathname: '/edit-customer-scheduler',
      state: selectedCustomer,
    });
  };

  const getCustomerName = (customer) => {
    if (customer.name) {
      return customer.name;
    } else {
      let name = '';
      name = customer.firstname || '';
      name = name + ' ' + customer.lastname || '';
      return name || 'N/A';
    }
  };

  return (
    <div className="main">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="leftPortion">
        <NavBar data="All Customers" icon={Settings} currentRouteName="Customers" breadcrumbsPath="Customers Details" />
        <div className="content">
          <div className="warp_edit_row">
            <div className="editRow">
              <div className="wrap_edit">
                <p>Edit:</p>
                <RoundButton className="disabled-button" handleClick={handleEditCustomerPlaylist} btnText="Playlist" />
                <RoundButton className="disabled-button" handleClick={handleEditCustomerScheduler} btnText="Scheduler" />
              </div>
            </div>
          </div>

          <TableContainer className="tbl_wraper" component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Connection</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{getCustomerName(selectedCustomer)}</TableCell>
                  <TableCell>{selectedCustomer.business_name || 'N/A'}</TableCell>
                  <TableCell>{selectedCustomer.address || 'N/A'}</TableCell>
                  <TableCell>{selectedCustomer.email}</TableCell>
                  <TableCell>{formatDateToDisplay(selectedCustomer.createdAt)}</TableCell>
                  <TableCell>
                    <span className="gray_circle"></span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div className="wrap_tbl playlist-scheduler-table">
            <TableContainer className="tbl_wraper result_tbl" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Result</TableCell>
                    <TableCell>Playlist</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(playlistAndScheduler) &&
                    playlistAndScheduler.map((playlist, index) => {
                      return (
                        <TableRow>
                          <TableCell>#{++index}</TableCell>
                          <TableCell>{playlist.name}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer className="tbl_wraper result_playlist" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Result</TableCell>
                    <TableCell>Playlist</TableCell>
                    <TableCell>Days</TableCell>
                    <TableCell>Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(playlistAndScheduler) &&
                    getSchedulesFromPlaylist(playlistAndScheduler).map((playlist, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontWeight: 'bold' }}>#{++index}</TableCell>
                        <TableCell>{playlist.name || 'N/A'}</TableCell>
                        <TableCell>{getShortNamesOfWeekDays(playlist.days)}</TableCell>
                        <TableCell>{playlist.time}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetails;
