/**
 * Name: NavBar
 * Author: @Gaurav
 * Date: 09-21-2020
 */
import React from 'react';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';
import './NavBar.scss';

const NavBar = (props) => {
  const { noBreadCrumb, currentRouteName = '', breadcrumbsPath = '' } = props;
  return (
    <>
      <div className="navBar">
        <h3>{props.data}</h3>
        {/* <button onClick={() => console.log('')}>
          <img className="icon" src={props.icon} />
        </button> */}
      </div>
      {!noBreadCrumb ? (
        <div className="wrap_breadcum">
          <Breadcrumbs aria-label="breadcrumb">
            <Link className="active_link" to="/">
              {currentRouteName}
            </Link>
            <Typography className="bredcum_link">{breadcrumbsPath}</Typography>
          </Breadcrumbs>
        </div>
      ) : null}
    </>
  );
};
export default NavBar;
