/**
 * Name: Get Customer Playlist Saga
 * Author: @Gaurav
 * Date: 23-07-2021
 */
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';

const callAPI = (payload) => {
  return ApiCaller(`/v1/admin/playlists/customer/${payload.customerId}`, 'get');
};

export default function* watchRequest() {
  yield takeLatest('GET_CUSTOMER_PLAYLIST', function* performAction(action) {
    yield put({ type: 'GET_CUSTOMER_PLAYLIST_INITIATED' });
    try {
      const data = yield call(callAPI.bind(this, action.payload));
      yield put({ type: 'GET_CUSTOMER_PLAYLIST_SUCCESS', payload: data });
    } catch (error) {
      yield put({ type: 'GET_CUSTOMER_PLAYLIST_FAILED', payload: error });
    }
  });
}

