import React from 'react';
import './SideBar.scss';
import logowhite from '../../assets/sidebar_logo.png';
import { Link } from 'react-router-dom';
import { sidebarData } from './SideBarData';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { SvgList } from '../';

const Sidebar = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();

  const handleLogout = () => {
    sessionStorage.removeItem('authData');
    sessionStorage.removeItem('expirationtime');
    
    history.push('/');
  };

  return (
    <div>
      <img className="logo" src={logowhite} />
      {sidebarData.manuList.map((item) => {
        return (
          <div key={item.manuName} className="wrap_column">
            <h5 className="heading">{item.manuName}</h5>
            <ul className="nav_list">
              {item.subMenu.map((subMenuItem) => {
                return (
                  <li className={subMenuItem.allPaths.includes(routeMatch.path) ? 'active' : ''} key={subMenuItem.name}>
                    <SvgList name={subMenuItem.iconName} />
                    <Link to={subMenuItem.route} className="link-style">
                      {subMenuItem.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
      <ul className="nav_list">
        <li>
          <SvgList name={'logout'} />
          <Link to={''} className="link-style" onClick={handleLogout}>
            {'Logout'}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
