/**
 * Name: PublicRoutes
 * Author: @Gaurav
 * Date: 09-21-2020
 */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { _isAuthenticated } from '../utils';
import { OnlyPublicPathNames } from './RouteData';

const PublicRoutes = ({ component: Component, ...rest }) => {
  const { tokens } = _isAuthenticated();

  /**
   * check the routes authenticity
   * @param props
   */
  const _getRoute = (props) => {
    if (tokens) {
      if (OnlyPublicPathNames.includes(props.location.pathname)) {
        return props.history.goBack();
      } else {
        return <Component {...props} />;
      }
    } else {
      return <Component {...props} />;
    }
  };

  return <Route render={(props) => _getRoute(props)} {...rest} />;
};

export default PublicRoutes;
