import { put, takeLatest } from 'redux-saga/effects';

/**
 * Clear the given state from the store
 */
export default function* watchRequest() {
  yield takeLatest('CLEAR_STATE', function* performAction(action) {
    try {
      yield put({
        type: `${action.actionName}_SUCCESS`,
        payload: {},
      });
    } catch (error) {
      throw error;
    }
  });
}
