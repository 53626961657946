/**
 * Name:Login saga
 * Author: @Gaurav
 * Date: 19-07-2021
 */

import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';

const callAPI = (payload) => {
  return ApiCaller('/login', 'post', payload).then((response) => response);
};

export default function* watchRequest() {
  yield takeLatest('LOGIN_USER', function* performAction(action) {
    yield put({ type: 'LOGIN_USER_INITIATED' });
    try {
      const data = yield call(callAPI.bind(this, action.payload));
      yield put({ type: 'LOGIN_USER_SUCCESS', payload: data });
    } catch (error) {
      yield put({ type: 'LOGIN_USER_FAILED', payload: error });
    }
  });
}
