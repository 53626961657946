/**
 * Name: SCHEDULE CUSTOMER SONG Saga
 * Author: @Gaurav
 * Date: 22-07-2021
 */
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';

const callAPI = (payload) => {
  return ApiCaller(`/v1/admin/playlists/customer/${payload.customerId}/playlist/${payload.playlistId}/schedule`, 'PATCH', {
    schedule: payload.schedule,
  });
};

export default function* watchRequest() {
  yield takeLatest('SCHEDULE_CUSTOMER_SONG', function* performAction(action) {
    yield put({ type: 'SCHEDULE_CUSTOMER_SONG_INITIATED' });
    try {
      const data = yield call(callAPI.bind(this, action.payload));
      yield put({ type: 'SCHEDULE_CUSTOMER_SONG_SUCCESS', payload: data });
    } catch (error) {
      yield put({ type: 'SCHEDULE_CUSTOMER_SONG_FAILED', payload: error });
    }
  });
}

