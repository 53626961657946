/**
 * Name: Modal
 * Author: @Gaurav
 * Date: 27-07-2021
 */
import React from 'react';
import { Button, Modal } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './style.scss';

const AlertModal = (props) => {
  const onClose = props?.onClose;
  const handelDelete = props?.handelDelete;
  const disabled = true;
  const body = (
    <div className="modal_wrap">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Are you sure want to delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={disabled ? 'dft_btn disable_color' : 'dft_btn'} onClick={onClose}>
          Cancel
        </Button>
        <Button className="dft_btn ok_btn" onClick={handelDelete}>
          Ok
        </Button>
      </DialogActions>
    </div>
  );
  return (
    <div>
      <Modal {...props} aria-labelledby="delete-modal-title" aria-describedby="delete-modal-description">
        {body}
      </Modal>
    </div>
  );
};
export default AlertModal;
