import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import RoundButton from '../../components/RoundButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Delete from '../../assets/Delete.png';
import search from '../../assets/ic_search.png';
import {
  _showAlert,
  formatDateToDisplay,
  diffArray,
  getWeekDays,
  getShortNamesOfWeekDays,
  getSchedulesFromPlaylist,
  getDaysNameFromArrayIndex,
} from '../../utils';
import './EditCustomerScheduler.scss';
import { LoaderSpinner } from '../../components';
import AlertModal from '../../components/Modal';

const useStyles = makeStyles({
  table: {
    //   minWidth: 700,
    borderRadius: 30,
  },
});

const HOURS = Array.from({ length: 24 }, (_, i) => (i.toString().length === 1 ? '0' + i : i.toString()));
const MINUTES = Array.from({ length: 60 }, (_, i) => (i.toString().length === 1 ? '0' + i : i.toString()));

function EditCustomerSecular(props) {
  const classes = useStyles();
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [selectedPlaylistToUpload, setPlaylistToUpload] = useState([]);
  const [hour, setHour] = useState('00');
  const [minute, setMinute] = useState('00');
  const [selectedWeekDays, setWeekDays] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [customerAllPlaylist, setCustomerAllPlaylist] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [schedulesList, setSchedulesList] = useState([]);

  const dispatch = useDispatch();

  /**
   * Close Modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * getting state from redux store
   */
  const { deleteCustomerSchedule, getCustomerPlaylist, scheduleCustomerSong } = useSelector((state) => ({
    deleteCustomerSchedule: state.deleteCustomerSchedule.result,
    getCustomerPlaylist: state.getCustomerPlaylist.result,
    scheduleCustomerSong: state.scheduleCustomerSong.result,
  }));
  /**
   * call the get customer playlist api
   * watch the local state selectedCustomer changes to search the playlist
   */
  useEffect(() => {
    fetchCustomerPlaylist();
  }, [selectedCustomer]);

  /**
   * check the router params
   */
  useEffect(() => {
    if (props?.history?.location?.state) {
      setSelectedCustomer(props.history.location.state);
    }
  }, [props.history]);

  /**
   * get all playlist for customer handler
   * watch the state chagnes of the getCustomerPlaylist state
   */
  useEffect(() => {
    if (Array.isArray(getCustomerPlaylist)) {
      setCustomerAllPlaylist(getCustomerPlaylist);
      setSchedulesList(getSchedulesFromPlaylist(getCustomerPlaylist));
    }
    if (getCustomerPlaylist?.error) {
      _showAlert(getCustomerPlaylist?.error, 'error', 8000);
    }
  }, [getCustomerPlaylist]);

  /**
   * delete scheduler api handler
   * delete scheduler state watcher
   */
  useEffect(() => {
    if (deleteCustomerSchedule?.status === 200) {
      fetchCustomerPlaylist();
      _showAlert('Deleted Successfully', 'success');
      setLoading(false);
    }
    if (deleteCustomerSchedule?.error) {
      setLoading(false);
      _showAlert(deleteCustomerSchedule?.error, 'error', 8000);
    }
  }, [deleteCustomerSchedule]);

  /**
   * scheduleCustomerSong api handler
   * scheduleCustomerSong state watcher
   */
  useEffect(() => {
    if (scheduleCustomerSong?.status === 200) {
      fetchCustomerPlaylist();
      _showAlert('Schedule Updated Successfully', 'success', 6000);
      setPlaylistToUpload([]);
      setHour('00');
      setMinute('00');
      setWeekDays([]);
      setLoading(false);
    }
    if (scheduleCustomerSong?.error) {
      _showAlert(scheduleCustomerSong.error, 'error');
      setLoading(false);
    }
  }, [scheduleCustomerSong]);

  const fetchCustomerPlaylist = () => {
    selectedCustomer.id && dispatch({ type: 'GET_CUSTOMER_PLAYLIST', payload: { customerId: selectedCustomer.id } });
  };

  const handleEditCustomerPlaylist = () => {
    props.history.push({
      pathname: '/edit-customer-playlists',
      state: selectedCustomer,
    });
  };

  const handleSelectSongsToUpload = (event, playlist) => {
    let checked = event.target.checked;
    if (checked) {
      setPlaylistToUpload([playlist.id]);
    } else {
      setPlaylistToUpload([]);
    }
  };

  const handleAddPlaylistToCustomer = () => {
    const time = `${hour}:${minute}`;
    let error = false;
    let playlistToUpload = customerAllPlaylist.find((item) => item.id === selectedPlaylistToUpload[0]);
    if (!playlistToUpload) {
      error = true;
      _showAlert(`Please select playlist to continue`, 'error', 10000);
      return;
    }
    if (!selectedWeekDays?.length) {
      error = true;
      _showAlert(`Please select days to continue`, 'error', 10000);
      return;
    }
    if (Array.isArray(schedulesList)) {
      let playlistWithTime = {};
      for (let i = 0; i < schedulesList.length; i++) {
        let item = schedulesList[i];
        if (playlistWithTime[item.time]) {
          playlistWithTime[item.time] = [...playlistWithTime[item.time], ...item.days];
        } else {
          playlistWithTime[item.time] = item.days;
        }
      }
      if (playlistWithTime[time]) {
        let duplicateDays = diffArray(selectedWeekDays, playlistWithTime[time]);
        if (duplicateDays?.length) {
          error = true;
          _showAlert(`${getDaysNameFromArrayIndex(duplicateDays)} already scheduled for time ${time}`, 'error', 10000);
          return;
        }
      }
    }
    if (!error) {
      setLoading(true);
      let schedule = playlistToUpload.schedule
        ? [...playlistToUpload?.schedule, { days: selectedWeekDays, time: `${hour}:${minute}` }]
        : [{ days: selectedWeekDays, time: `${hour}:${minute}` }];
      dispatch({
        type: 'SCHEDULE_CUSTOMER_SONG',
        payload: {
          customerId: selectedCustomer.id,
          playlistId: selectedPlaylistToUpload[0],
          schedule,
        },
      });
    }
  };

  const handleDeleteCustomerSchedule = (playlist) => {
    setLoading(true);
    setOpen(false);
    let playlistToUpload = getCustomerPlaylist.find((item) => item.id === playlist.playlistId);
    let newSchedules = playlistToUpload.schedule.filter((item) => {
      let playlistDays = JSON.stringify(item.days.sort());
      let selectedDays = JSON.stringify(playlist.days.sort());
      if (playlist.time === item.time && playlistDays === selectedDays) {
        return null;
      }
      return item;
    });
    if (newSchedules.length) {
      dispatch({
        type: 'SCHEDULE_CUSTOMER_SONG',
        payload: { customerId: selectedCustomer.id, playlistId: playlist.playlistId, schedule: newSchedules },
      });
    } else {
      dispatch({
        type: 'DELETE_CUSTOMER_SCHEDULE',
        payload: { customerId: selectedCustomer.id, playlistId: playlist.playlistId },
      });
    }
  };

  const handleChangeWeekDays = (event, day) => {
    if (event.target.checked) {
      setWeekDays([...selectedWeekDays, day.value]);
    } else {
      setWeekDays(selectedWeekDays.filter((item) => item !== day.value));
    }
  };

  const getCustomerName = (customer) => {
    if (customer.name) {
      return customer.name;
    } else {
      let name = '';
      name = customer.firstname || '';
      name = name + ' ' + customer.lastname || '';
      return name || 'N/A';
    }
  };

  const handleSearchInput = (value) => {
    let str = value.toLowerCase();
    setSearchInput(value);
    if (Array.isArray(getCustomerPlaylist)) {
      if (value) {
        let newlist = getCustomerPlaylist.filter((item) => item.name.toLowerCase().indexOf(str) !== -1);
        setCustomerAllPlaylist([...newlist]);
      } else {
        setCustomerAllPlaylist([...getCustomerPlaylist]);
      }
    }
  };

  /**
   * get called on component unmount
   * clear the redux state of the component
   */
  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'SCHEDULE_CUSTOMER_SONG' });
      dispatch({ type: 'CLEAR_STATE', actionName: 'DELETE_CUSTOMER_SCHEDULE' });
    };
  }, []);

  return (
    <div className="main">
      {isLoading ? <LoaderSpinner /> : null}
      <div className="sidebar">
        <Sidebar />
      </div>
      <AlertModal open={open} onClose={handleClose} handelDelete={() => handleDeleteCustomerSchedule(selectedPlaylist)} />
      <div className="leftPortion">
        <NavBar data="All Customers" icon={Settings} currentRouteName="Customers" breadcrumbsPath="Edit Customer Scheduler" />
        <div className="content">
          <div className="warp_edit_row">
            <div className="editRow">
              <div className="wrap_edit">
                <p>Edit:</p>
                <RoundButton className="disabled-button" btnText="Playlist" handleClick={handleEditCustomerPlaylist} />
                <RoundButton btnText="Scheduler" />
              </div>
            </div>
          </div>

          <TableContainer className="tbl_wraper" component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell className="text_center">Connection</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{getCustomerName(selectedCustomer)}</TableCell>
                  <TableCell>{selectedCustomer.business_name || 'N/A'}</TableCell>
                  <TableCell>{selectedCustomer.address || 'N/A'}</TableCell>
                  <TableCell>{selectedCustomer.email}</TableCell>
                  <TableCell>{formatDateToDisplay(selectedCustomer.createdAt)}</TableCell>
                  <TableCell>
                    <span className="gray_circle"></span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div className="wrap_tbl custmr_tbl">
            <div className="wrap_tbl_lft">
              <TableContainer className="tbl_wraper" component={Paper}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell colspan="3">
                        <div className="search_row wdth100">
                          <input
                            className="input_field"
                            placeholder="Search something here..."
                            type="text"
                            value={searchInput}
                            onChange={(e) => handleSearchInput(e.target.value)}
                            name="searchInput"
                          />
                          <img src={search} />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerAllPlaylist.map((playlist, index) => (
                      <TableRow key={playlist.name}>
                        <TableCell>
                          <Checkbox
                            checked={selectedPlaylistToUpload.includes(playlist.id)}
                            onChange={(event) => handleSelectSongsToUpload(event, playlist)}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>#{++index}</TableCell>
                        <TableCell>{playlist.name}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="days_row">
                <p>Date:</p>
                <ul>
                  {getWeekDays().map((item) => {
                    return (
                      <li key={item.value}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedWeekDays.includes(item.value) || false}
                              name={item.name}
                              onChange={(event) => handleChangeWeekDays(event, item)}
                            />
                          }
                          label={item.label}
                        />
                      </li>
                    );
                  })}
                </ul>
                <div className="time_add_row">
                  <span className="time_row">
                    <p>Time:</p>
                    <select placeholder="00" name="hour" value={hour} required onChange={(event) => setHour(event.target.value)}>
                      {HOURS.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <select placeholder="00" name="minute" value={minute} required onChange={(event) => setMinute(event.target.value)}>
                      {MINUTES.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </span>
                  <RoundButton handleClick={handleAddPlaylistToCustomer} btnText="Add" />
                </div>
              </div>
            </div>

            <TableContainer className="tbl_wraper result_playlist" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Result</TableCell>
                    <TableCell>Playlist</TableCell>
                    <TableCell>Days</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell className="text_center">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(schedulesList) &&
                    schedulesList.map((scheduleItem, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontWeight: 'bold' }}>#{++index}</TableCell>
                        <TableCell>{scheduleItem.name || 'N/A'}</TableCell>
                        <TableCell>{getShortNamesOfWeekDays(scheduleItem.days)}</TableCell>
                        <TableCell>{scheduleItem.time}</TableCell>
                        <TableCell>
                          <img
                            onClick={() => {
                              setOpen(true);
                              setSelectedPlaylist(scheduleItem);
                            }}
                            src={Delete}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditCustomerSecular;
