/**
 * Name: Get Customer Playlist Reducer
 * Author: @Gaurav
 * Date: 23-07-2021
 */
export default function reducer(
  state = {
    status: 'uninitiated',
    result: {},
  },
  action
) {
  switch (action.type) {
    case 'ADD_CUSTOMER_PLAYLIST_INITIATED': {
      return { ...state, status: 'initiated' };
    }
    case 'ADD_CUSTOMER_PLAYLIST_SUCCESS': {
      return { ...state, status: 'success', result: action.payload };
    }
    case 'ADD_CUSTOMER_PLAYLIST_FAILED': {
      return { ...state, status: 'failed', result: action.payload };
    }
    default: {
      return state;
    }
  }
}
