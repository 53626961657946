import './RoundButton.scss';

function RoundButton(props) {
  return (
    <button type="button" className={`${props.className} round_btn`} onClick={props.handleClick}>
      {props.btnText}
    </button>
  );
}

export default RoundButton;
