import * as React from 'react';
const DeleteIconSvg = (props) => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 27C12.45 27 11.9793 26.8043 11.588 26.413C11.196 26.021 11 25.55 11 25V12C10.7167 12 10.479 11.9043 10.287 11.713C10.0957 11.521 10 11.2833 10 11C10 10.7167 10.0957 10.479 10.287 10.287C10.479 10.0957 10.7167 10 11 10H15C15 9.71667 15.096 9.479 15.288 9.287C15.4793 9.09567 15.7167 9 16 9H20C20.2833 9 20.521 9.09567 20.713 9.287C20.9043 9.479 21 9.71667 21 10H25C25.2833 10 25.5207 10.0957 25.712 10.287C25.904 10.479 26 10.7167 26 11C26 11.2833 25.904 11.521 25.712 11.713C25.5207 11.9043 25.2833 12 25 12V25C25 25.55 24.8043 26.021 24.413 26.413C24.021 26.8043 23.55 27 23 27H13ZM13 12V25H23V12H13ZM15 22C15 22.2833 15.096 22.5207 15.288 22.712C15.4793 22.904 15.7167 23 16 23C16.2833 23 16.521 22.904 16.713 22.712C16.9043 22.5207 17 22.2833 17 22V15C17 14.7167 16.9043 14.479 16.713 14.287C16.521 14.0957 16.2833 14 16 14C15.7167 14 15.4793 14.0957 15.288 14.287C15.096 14.479 15 14.7167 15 15V22ZM19 22C19 22.2833 19.096 22.5207 19.288 22.712C19.4793 22.904 19.7167 23 20 23C20.2833 23 20.521 22.904 20.713 22.712C20.9043 22.5207 21 22.2833 21 22V15C21 14.7167 20.9043 14.479 20.713 14.287C20.521 14.0957 20.2833 14 20 14C19.7167 14 19.4793 14.0957 19.288 14.287C19.096 14.479 19 14.7167 19 15V22ZM13 12V25V12Z"
      fill="#C04444"
    />
  </svg>
);
export default DeleteIconSvg;

