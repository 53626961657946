/**
 * Name: Customer List Reducer
 * Author: @Gaurav
 * Date: 20-07-2021
 */

export default function reducer(
  state = {
    status: 'uninitiated',
    result: {},
  },
  action
) {
  switch (action.type) {
    case 'CUSTOMER_LIST_INITIATED': {
      return { ...state, status: 'initiated' };
    }
    case 'CUSTOMER_LIST_SUCCESS': {
      return { ...state, status: 'success', result: action.payload };
    }
    case 'CUSTOMER_LIST_FAILED': {
      return { ...state, status: 'failed', result: action.payload };
    }
    default: {
      return state;
    }
  }
}
