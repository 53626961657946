import * as React from 'react';
const DownloadIcon = (props) => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18 21.1281C17.8833 21.1281 17.774 21.1097 17.6719 21.073C17.5698 21.0368 17.475 20.975 17.3875 20.8875L14.2375 17.7375C14.0771 17.5771 13.9969 17.3729 13.9969 17.125C13.9969 16.8771 14.0771 16.6729 14.2375 16.5125C14.3979 16.3521 14.6056 16.2681 14.8605 16.2605C15.116 16.2535 15.324 16.3302 15.4844 16.4906L17.125 18.1312V11.875C17.125 11.6271 17.209 11.4191 17.377 11.2511C17.5444 11.0837 17.7521 11 18 11C18.2479 11 18.4559 11.0837 18.6239 11.2511C18.7913 11.4191 18.875 11.6271 18.875 11.875V18.1312L20.5156 16.4906C20.676 16.3302 20.884 16.2535 21.1395 16.2605C21.3944 16.2681 21.6021 16.3521 21.7625 16.5125C21.9229 16.6729 22.0031 16.8771 22.0031 17.125C22.0031 17.3729 21.9229 17.5771 21.7625 17.7375L18.6125 20.8875C18.525 20.975 18.4302 21.0368 18.3281 21.073C18.226 21.1097 18.1167 21.1281 18 21.1281ZM12.75 25C12.2687 25 11.8569 24.8288 11.5145 24.4864C11.1715 24.1434 11 23.7312 11 23.25V21.5C11 21.2521 11.0837 21.0441 11.2511 20.8761C11.4191 20.7087 11.6271 20.625 11.875 20.625C12.1229 20.625 12.3309 20.7087 12.4989 20.8761C12.6663 21.0441 12.75 21.2521 12.75 21.5V23.25H23.25V21.5C23.25 21.2521 23.334 21.0441 23.502 20.8761C23.6694 20.7087 23.8771 20.625 24.125 20.625C24.3729 20.625 24.5806 20.7087 24.748 20.8761C24.916 21.0441 25 21.2521 25 21.5V23.25C25 23.7312 24.8288 24.1434 24.4864 24.4864C24.1434 24.8288 23.7312 25 23.25 25H12.75Z"
      fill="black"
    />
  </svg>
);
export default DownloadIcon;

