import React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import RoundButton from '../../components/RoundButton';

import '../CustomerList/CustomerList.scss';
import { CircularProgress } from '@mui/material';

const BlockUserModal = ({ setBlockUserModalOpen, setBlockUserDate, isLoading }) => {
  const [value, setValue] = React.useState(dayjs(new Date()));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="modalBackDrop" onClick={() => setBlockUserModalOpen(false)}>
        <div className="blockUserModal" onClick={(e) => e.stopPropagation()}>
          <p className="header">Open & Block User</p>
          <p className="label">Add expiry date</p>
          <DesktopDatePicker
            // label="Date desktop"
            inputFormat="MM/DD/YYYY"
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} variant="standard" label="Select Date" />}
            className="inputContainer"
          />
          {isLoading ? (
            <div style={{ marginTop: '50px' }}>
              <CircularProgress />
            </div>
          ) : (
            <RoundButton
              btnText="Confirm"
              className="btn"
              handleClick={() => {
                setBlockUserDate(new Date(value));
              }}
            />
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default BlockUserModal;

