export const _showAlert = (message = '', type = '', duration = 5000) => {
  const alertMain = document.getElementById('alert-box-warpper');
  const alertifyMessage = document.querySelectorAll('#alert-box-warpper .MuiAlert-message');
  const alertBox = document.querySelectorAll('#alert-box-warpper .MuiPaper-root');

  let alertTypeClass = 'MuiAlert-standardSuccess';

  if (type === 'error') {
    alertTypeClass = 'MuiAlert-standardError';
  }

  /**
   * add the success and failed class for alert box
   */
  alertBox.forEach((el) => {
    type === 'success' ? el.classList.remove('MuiAlert-standardError') : el.classList.remove('MuiAlert-standardSuccess');
    el.classList.add(alertTypeClass);
    setTimeout(() => {
      el.classList.remove(alertTypeClass);
    }, duration);
  });

  /**
   * add the display none or display class to the alert wrapper
   */

  alertMain.classList.add('alert-wrapper-active');
  setTimeout(() => {
    alertMain.classList.remove('alert-wrapper-active');
  }, duration);

  /**
   * Inject error or success message to the alert paragraph
   */
  alertifyMessage.forEach((el) => {
    el.innerHTML = message;
  });
};
