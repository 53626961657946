/**
 * Name: Customer list
 * Author: @Gaurav
 * Date: 20-07-2021
 */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import SearchIcon from '../../assets/ic_search.png';
import moreInfrom from '../../assets/moreInfo.svg';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import '../CustomerList/CustomerList.scss';
import MoreInfoPopUp from './MoreInfoPopUp';
import BlockUserModal from './BlockUserModal';
import LogsModal from './LogsModal';
import { _showAlert, formatDateToDisplay } from '../../utils';
import ApiCaller from '../../redux/ApiCaller';
import { CircularProgress } from '@material-ui/core';
import DeleteCustomerPopup from './DeleteCustomerPopup';

/**
 * material ui table style
 */
const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderRadius: 30,
  },
});

function CustomerList() {
  const customerList = useSelector((state) => state.customerList.result);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [customer, setCustomer] = useState('');

  // stats for handle more info icon
  const [isActionPopUpOpen, setIsActionPopUpOpen] = useState(false);
  const [xPos, setxPos] = useState(0);
  const [yPos, setyPos] = useState(0);

  // setting the curret customer
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [isDeletePopUpVisible, setIsDeletePopUpVisible] = useState(false);

  const [blockUserDate, setBlockUserDate] = useState(null);
  const [blockUserModalOpen, setBlockUserModalOpen] = useState(false);
  const [isUserUpadating, setIsUserUpading] = useState(false);
  const [logsModalOpen, setLogsModalOpen] = useState(false);
  const [isRefershing, setIsRefreshing] = useState(false);

  /**
   * It will navigate customer details page
   */
  const handleCustomerDetails = () => {
    history.push({
      pathname: '/customer-details',
      state: selectedCustomer,
    });
  };

  /**
   * It will navigate to edit customer detail page
   */
  const handleCustomerEditPage = () => {
    history.push({
      pathname: '/update-customer',
      state: selectedCustomer,
    });
  };

  useEffect(() => {
    dispatch({ type: 'CUSTOMER_LIST', payload: customer });
  }, [customer]);

  const getCustomerName = (customer) => {
    if (customer.name) {
      return customer.name;
    } else {
      let name = '';
      name = customer.firstname || '';
      name = name + ' ' + customer.lastname || '';
      return name || 'N/A';
    }
  };

  const handleMoreInfoClick = (event, customer) => {
    const { innerHeight: height } = window;
    let xPos = event.clientX;
    let yPos = event.clientY - event.target.offsetTop;

    if (yPos + 360 > height) {
      yPos = event.clientY - 330;
    }
    setIsActionPopUpOpen(true);
    setxPos(xPos);
    setyPos(yPos);
    setSelectedCustomer(customer);
  };

  const handleRefreshClick = async () => {
    try {
      setIsRefreshing(true);
      const data = await ApiCaller(`/v1/admin/customer/${selectedCustomer.id}/actions/restart_device`, 'POST', {});
      console.log(data);
      setIsRefreshing(false);
    } catch (error) {
      setIsRefreshing(true);
      _showAlert(error.message, 'error');
    }
  };
  const handleSetExirationDate = async (e) => {
    try {
      setIsUserUpading(true);
      const expiration_date = Math.floor(e.getTime() / 1000);
      const payload = {
        id: selectedCustomer?.id,
        expiration_date: Math.floor(e.getTime() / 1000),
      };
      await ApiCaller('/v1/admin/customer', 'post', payload);

      const updatedCustomer = {
        id: selectedCustomer.id,
        name: selectedCustomer.name,
        email: selectedCustomer.email,
        address: selectedCustomer.address,
        userRole: selectedCustomer.userRole,
        createdAt: selectedCustomer.createdAt,
        firstname: selectedCustomer.firstname,
        lastname: selectedCustomer.lastname,
        business_name: selectedCustomer.business_name,
        username: selectedCustomer.username,
        phone: selectedCustomer.phone,
        expiration_date: expiration_date,
        is_online: selectedCustomer.is_online,
        status: selectedCustomer.status,
      };

      dispatch({ type: 'CUSTOMER_LIST', payload: '' });
      setIsUserUpading(false);
      setBlockUserModalOpen(false);
    } catch (error) {
      setBlockUserModalOpen(false);
      setIsUserUpading(false);
      _showAlert(error.message, 'error');
    }
  };

  const handleShuffle = async () => {
    try {
      setIsRefreshing(true);
      const data = await ApiCaller(`/v1/admin/customer/${selectedCustomer.id}/actions/shuffle`, 'POST', {
        shuffle: true,
      });
      console.log(data);
      setIsRefreshing(false);
    } catch (error) {
      setIsRefreshing(true);
      _showAlert(error.message, 'error');
    }
  };

  const onDeleteIconClick = () => {
    setIsDeletePopUpVisible(true);
    setIsActionPopUpOpen(false);
  };

  return (
    <>
      {isRefershing && (
        <div className="modalBackDrop" style={{ zIndex: 10 }}>
          <CircularProgress />
        </div>
      )}
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="leftPortion">
          <NavBar data="All Customer" currentRouteName="Customers" breadcrumbsPath="Customers List" />
          <div className="content">
            <div className="search_row">
              <input
                className="input_field"
                placeholder="search something Here..."
                type="text"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
              />
              <img alt="Hello" src={SearchIcon} />
            </div>
            <TableContainer className="tbl_wraper customer-list-main" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table_font">Result</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Business Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>connection</TableCell>
                    <TableCell>Expiry Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(customerList) &&
                    customerList.map((customer, index) => (
                      <TableRow key={customer.id}>
                        <TableCell style={{ fontWeight: 'bold' }}>#{++index}</TableCell>
                        <TableCell>{getCustomerName(customer)}</TableCell>
                        <TableCell>{customer.business_name || 'N/A'}</TableCell>
                        <TableCell>{customer.address || 'N/A'}</TableCell>
                        <TableCell>
                          <span
                            style={{ backgroundColor: customer?.is_online ? '#1CC700' : '#C70000' }}
                            className="indication_circle"
                          ></span>
                        </TableCell>
                        <TableCell>{customer.expiration_date ? formatDateToDisplay(customer.expiration_date * 1000) : '-'}</TableCell>
                        <TableCell>
                          <img onClick={(e) => handleMoreInfoClick(e, customer)} alt="customer details" src={moreInfrom} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {isActionPopUpOpen && (
        <MoreInfoPopUp
          setIsActionPopUpOpen={setIsActionPopUpOpen}
          xPos={xPos}
          yPos={yPos}
          handleCustomerDetails={handleCustomerDetails}
          handleCustomerEditPage={handleCustomerEditPage}
          setBlockUserModalOpen={setBlockUserModalOpen}
          setLogsModalOpen={setLogsModalOpen}
          handleRefreshClick={handleRefreshClick}
          onDeleteIconClick={onDeleteIconClick}
          handleShuffle={handleShuffle}
        />
      )}
      {blockUserModalOpen && (
        <BlockUserModal
          isLoading={isUserUpadating}
          setBlockUserModalOpen={setBlockUserModalOpen}
          setBlockUserDate={handleSetExirationDate}
        />
      )}
      {logsModalOpen && <LogsModal setLogsModalOpen={setLogsModalOpen} selectedCustomer={selectedCustomer} />}
      {isDeletePopUpVisible && (
        <DeleteCustomerPopup selectedCustomer={selectedCustomer} setIsDeletePopUpVisible={setIsDeletePopUpVisible} />
      )}
    </>
  );
}

export default CustomerList;

