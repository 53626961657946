/**
 * Name: Search Songs
 * Author: @Gaurav
 * Date: 09-21-2020
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import search from '../../assets/ic_search.png';
import Delete from '../../assets/Delete.png';
// import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './SearchSongs.scss';
import { _showAlert, formatDateToDisplay } from '../../utils';
import AlertModal from '../../components/Modal';

/**
 * material ui table style object
 */
const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderRadius: 30,
  },
});

function SearchSong() {
  const songsList = useSelector((state) => state.searchSongs.result);
  const deleteSongs = useSelector((state) => state.deleteSongs);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState('');
  const [open, setOpen] = React.useState(false);
  const [selectedSongs, setSelectedSongs] = useState(null);

  /**
   * Close Modal
   */
  const handleClose = () => {
    setOpen(false);
  };
  
  /**
   * It will arrange the list according to date
   */
  function songs_sort(a, b) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }
  
  /**
   * It will handle the delete songs api handling
   */
  const handleDeleteSong = (song) => {
    setOpen(false);
    dispatch({ type: 'DELETE_SONGS', payload: { ids: [song.id] } });
  };

  useEffect(() => {
    if (deleteSongs.status == 'success') {
      dispatch({ type: 'SEARCH_SONGS', payload: '' });
    }
  }, [deleteSongs]);

  /**
   * It will handle Serach Songs
   * watch the changes of the searchInput component state
   */
  useEffect(() => {
    dispatch({ type: 'SEARCH_SONGS', payload: searchInput });
  }, [searchInput]);

  /**
   * get called on component unmount
   * clear the delete song redux state
   */
  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'DELETE_SONGS' });
    };
  }, []);

  return (
    <div className="main">
      <div className="sidebar">
        <Sidebar />
      </div>
      <AlertModal open={open} onClose={handleClose} handelDelete={() => handleDeleteSong(selectedSongs)} />
      <div className="leftPortion">
        <NavBar data="Search song" icon={Settings} currentRouteName="Songs" breadcrumbsPath="Search Songs" />
        <div className="content">
          <div className="search_row">
            <input
              className="input_field"
              placeholder="search songs Here..."
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <img src={search} />
          </div>
          <TableContainer className="tbl_wraper" component={Paper}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>{/* <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /> */}</TableCell>
                  <TableCell className="table_font">Result</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Song Name</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(songsList) &&
                  songsList.sort(songs_sort).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{/* <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /> */}</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>#{++index}</TableCell>
                      <TableCell>{formatDateToDisplay(row.createdAt)}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>
                        <img
                          onClick={() => {
                            setOpen(true);
                            setSelectedSongs(row);
                          }}
                          src={Delete}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default SearchSong;
