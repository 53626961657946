import * as React from 'react';
const BlockIconSvg = (props) => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 28C11.45 28 10.9793 27.8043 10.588 27.413C10.196 27.021 10 26.55 10 26V16C10 15.45 10.196 14.979 10.588 14.587C10.9793 14.1957 11.45 14 12 14H13V12C13 10.6167 13.4877 9.43733 14.463 8.462C15.4377 7.48733 16.6167 7 18 7C19.3833 7 20.5627 7.48733 21.538 8.462C22.5127 9.43733 23 10.6167 23 12V14H24C24.55 14 25.021 14.1957 25.413 14.587C25.8043 14.979 26 15.45 26 16V26C26 26.55 25.8043 27.021 25.413 27.413C25.021 27.8043 24.55 28 24 28H12ZM12 26H24V16H12V26ZM18 23C18.55 23 19.021 22.8043 19.413 22.413C19.8043 22.021 20 21.55 20 21C20 20.45 19.8043 19.979 19.413 19.587C19.021 19.1957 18.55 19 18 19C17.45 19 16.9793 19.1957 16.588 19.587C16.196 19.979 16 20.45 16 21C16 21.55 16.196 22.021 16.588 22.413C16.9793 22.8043 17.45 23 18 23ZM15 14H21V12C21 11.1667 20.7083 10.4583 20.125 9.875C19.5417 9.29167 18.8333 9 18 9C17.1667 9 16.4583 9.29167 15.875 9.875C15.2917 10.4583 15 11.1667 15 12V14ZM12 26V16V26Z"
      fill="black"
    />
  </svg>
);
export default BlockIconSvg;

