import * as React from 'react';
const InfoIconSvg = (props) => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.8889 21.8V17H16V18.2H17.1111V21.8H16V23H21V21.8H19.8889Z" fill="black" />
    <path
      d="M18.5 8C16.4233 8 14.3932 8.61581 12.6665 9.76957C10.9398 10.9233 9.59399 12.5632 8.79927 14.4818C8.00455 16.4004 7.79661 18.5116 8.20176 20.5484C8.6069 22.5852 9.60693 24.4562 11.0754 25.9246C12.5438 27.3931 14.4148 28.3931 16.4516 28.7982C18.4884 29.2034 20.5996 28.9955 22.5182 28.2007C24.4368 27.406 26.0767 26.0602 27.2304 24.3335C28.3842 22.6068 29 20.5767 29 18.5C28.9969 15.7162 27.8897 13.0472 25.9212 11.0788C23.9528 9.11029 21.2838 8.00306 18.5 8V8ZM18.5 26.9C16.8386 26.9 15.2146 26.4073 13.8332 25.4843C12.4518 24.5613 11.3752 23.2494 10.7394 21.7145C10.1036 20.1796 9.93729 18.4907 10.2614 16.8612C10.5855 15.2318 11.3855 13.7351 12.5603 12.5603C13.7351 11.3855 15.2318 10.5855 16.8612 10.2614C18.4907 9.93729 20.1796 10.1036 21.7145 10.7394C23.2494 11.3752 24.5613 12.4518 25.4843 13.8332C26.4073 15.2146 26.9 16.8386 26.9 18.5C26.8975 20.727 26.0117 22.8622 24.4369 24.4369C22.8622 26.0117 20.7271 26.8975 18.5 26.9V26.9Z"
      fill="black"
    />
    <path
      d="M18.5 16C19.3284 16 20 15.3284 20 14.5C20 13.6716 19.3284 13 18.5 13C17.6716 13 17 13.6716 17 14.5C17 15.3284 17.6716 16 18.5 16Z"
      fill="black"
    />
  </svg>
);
export default InfoIconSvg;

