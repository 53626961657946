/**
 * Name: NotFound
 * Author: @Gaurav
 * Date: 09-21-2020
 */
import React from 'react';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';

const NotFound = () => {
  return (
    <div className="main empty_page">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="leftPortion">
        <NavBar noBreadCrumb data="Not Found" icon={Settings} />
        <div className="content empty_row">
          <p>No page found with current route</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
