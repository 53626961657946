import React from 'react';

const SvgList = ({ name }) => {
  switch (name) {
    case 'add-songs':
      return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 2C8.0428 2 6.41593 3.19695 5.8335 4.80733C5.70603 5.1598 5.39219 5.4113 5.02046 5.4591C3.27665 5.683 2 7.09 2 8.7143C2 10.4835 3.52047 12 5.5 12C6.05228 12 6.5 12.4477 6.5 13C6.5 13.5523 6.05228 14 5.5 14C2.50897 14 0 11.6789 0 8.7143C0 6.1997 1.80914 4.14473 4.18099 3.58194C5.19963 1.45093 7.43762 0 10 0C13.1743 0 15.8715 2.24014 16.331 5.2344C18.3965 5.6456 20 7.3977 20 9.5714C20 12.0627 17.894 14 15.4 14C14.8477 14 14.4 13.5523 14.4 13C14.4 12.4477 14.8477 12 15.4 12C16.8825 12 18 10.8673 18 9.5714C18 8.2756 16.8825 7.1429 15.4 7.1429C14.8477 7.1429 14.4 6.6951 14.4 6.1429C14.4 3.90024 12.4766 2 10 2Z"
            fill="#BFBFBF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 14C9.4477 14 9 13.5523 9 13V7C9 6.4477 9.4477 6 10 6C10.5523 6 11 6.4477 11 7V13C11 13.5523 10.5523 14 10 14Z"
            fill="#BFBFBF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29289 9.7071C6.90237 9.3166 6.90237 8.6834 7.29289 8.2929L9.2929 6.2929C9.4804 6.1054 9.7348 6 10 6C10.2652 6 10.5196 6.1054 10.7071 6.2929L12.7071 8.2929C13.0976 8.6834 13.0976 9.3166 12.7071 9.7071C12.3166 10.0976 11.6834 10.0976 11.2929 9.7071L10 8.4142L8.7071 9.7071C8.3166 10.0976 7.68342 10.0976 7.29289 9.7071Z"
            fill="#BFBFBF"
          />
        </svg>
      );
    case 'search-songs':
      return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              d="M11.5632 17.961C6.87814 17.961 3.08008 14.1629 3.08008 9.4778C3.08008 4.79267 6.8781 0.99469 11.5632 0.99469C16.2484 0.99469 20.0464 4.79271 20.0464 9.47785C20.0464 14.163 16.2483 17.961 11.5632 17.961ZM11.5632 3.41846C8.21671 3.41846 5.50385 6.13132 5.50385 9.47785C5.50385 12.8244 8.21671 15.5372 11.5632 15.5372C14.9098 15.5372 17.6226 12.8244 17.6226 9.47785C17.6226 6.13132 14.9097 3.41846 11.5632 3.41846Z"
              fill="#BFBFBF"
            />
            <path
              d="M1.8682 20.3847C1.54607 20.3866 1.23646 20.2601 1.00777 20.0332C0.532549 19.5619 0.529416 18.7946 1.00075 18.3194C1.00309 18.3171 1.00543 18.3147 1.00777 18.3124L5.56442 13.7557C6.05635 13.2805 6.84038 13.2941 7.31561 13.786C7.79083 14.278 7.77725 15.062 7.28532 15.5372L2.72863 20.0332C2.49994 20.2601 2.19033 20.3866 1.8682 20.3847Z"
              fill="#BFBFBF"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="19.3971" height="19.3971" fill="white" transform="translate(0.649292 0.99118)" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'new-playlist':
      return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1547 7H9.14182V0H7.13814V7H0.125244V9H7.13814V16H9.14182V9H16.1547V7Z" fill="#BFBFBF" />
        </svg>
      );
    case 'edit-playlist':
      return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.1418 4.586L3.11975 16.586V23H9.54556L21.5677 11L15.1418 4.586ZM18.7345 11L17.1455 12.586L13.5529 9L15.1418 7.414L18.7345 11ZM8.71604 21H5.12343V17.414L12.1363 10.414L15.7289 14L8.71604 21Z"
            fill="#BFBFBF"
          />
        </svg>
      );
    case 'all-customers':
      return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.1418 0C4.61767 0 0.123413 4.486 0.123413 10C0.123413 15.514 4.61767 20 10.1418 20C15.666 20 20.1602 15.514 20.1602 10C20.1602 4.486 15.666 0 10.1418 0ZM5.3941 16.43C6.05933 14.407 7.94379 13 10.1418 13C12.3399 13 14.2243 14.407 14.8896 16.43C13.5591 17.412 11.9201 18 10.1418 18C8.36356 18 6.72455 17.412 5.3941 16.43ZM7.63723 8.5C7.63723 7.122 8.76129 6 10.1418 6C11.5224 6 12.6464 7.122 12.6464 8.5C12.6464 9.878 11.5224 11 10.1418 11C8.76129 11 7.63723 9.878 7.63723 8.5ZM16.4434 14.926C15.7632 13.53 14.6411 12.411 13.2666 11.731C14.1161 10.912 14.6501 9.769 14.6501 8.5C14.6501 6.019 12.6274 4 10.1418 4C7.65626 4 5.63354 6.019 5.63354 8.5C5.63354 9.769 6.16752 10.912 7.01709 11.731C5.64256 12.411 4.5205 13.529 3.84025 14.926C2.77128 13.566 2.1271 11.858 2.1271 10C2.1271 5.589 5.72271 2 10.1418 2C14.561 2 18.1566 5.589 18.1566 10C18.1566 11.858 17.5124 13.566 16.4434 14.926Z"
            fill="#BFBFBF"
          />
        </svg>
      );
    case 'new-customer':
      return (
        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.1621 9H17.1565V6H15.1529V9H12.1473V11H15.1529V14H17.1565V11H20.1621V9Z" fill="#BFBFBF" />
          <path
            d="M9.14182 11H5.13445C2.37238 11 0.125244 13.243 0.125244 16V19H14.151V16C14.151 13.243 11.9039 11 9.14182 11ZM12.1473 17H2.12893V16C2.12893 14.346 3.47741 13 5.13445 13H9.14182C10.7989 13 12.1473 14.346 12.1473 16V17Z"
            fill="#BFBFBF"
          />
          <path
            d="M7.13811 10C9.90019 10 12.1473 7.757 12.1473 5C12.1473 2.243 9.90019 0 7.13811 0C4.37604 0 2.12891 2.243 2.12891 5C2.12891 7.757 4.37604 10 7.13811 10ZM7.13811 2C8.79516 2 10.1436 3.346 10.1436 5C10.1436 6.654 8.79516 8 7.13811 8C5.48107 8 4.13259 6.654 4.13259 5C4.13259 3.346 5.48107 2 7.13811 2Z"
            fill="#BFBFBF"
          />
        </svg>
      );
    case 'logout':
      return (
        <svg width="23" height="19" viewBox="0 0 80 122.88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M79.128,64.598H40.069c-1.726,0-3.125-1.414-3.125-3.157c0-1.744,1.399-3.158,3.125-3.158h39.057L66.422,43.733 c-1.14-1.301-1.019-3.289,0.269-4.439c1.288-1.151,3.257-1.03,4.396,0.271l17.281,19.792c1.061,1.211,1.029,3.019-0.02,4.19 l-17.262,19.77c-1.14,1.302-3.108,1.423-4.396,0.271c-1.287-1.151-1.408-3.139-0.269-4.44L79.128,64.598L79.128,64.598z M42.396,116.674c1.689,0.409,2.727,2.11,2.318,3.799c-0.409,1.689-2.109,2.728-3.799,2.318c-3.801-0.922-7.582-1.671-11.052-2.358 C10.426,116.583,0,114.519,0,86.871V34.188C0,7.96,11.08,5.889,29.431,2.46c3.572-0.667,7.448-1.391,11.484-2.371 c1.689-0.409,3.39,0.629,3.799,2.319c0.408,1.689-0.629,3.39-2.318,3.799c-4.291,1.041-8.201,1.771-11.805,2.445 C15.454,11.48,6.315,13.188,6.315,34.188v52.683c0,22.467,8.643,24.179,24.756,27.37C34.453,114.911,38.138,115.642,42.396,116.674 L42.396,116.674z"
            fill="#BFBFBF"
          />
        </svg>
      );
    default:
      return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              d="M11.5632 17.961C6.87814 17.961 3.08008 14.1629 3.08008 9.4778C3.08008 4.79267 6.8781 0.99469 11.5632 0.99469C16.2484 0.99469 20.0464 4.79271 20.0464 9.47785C20.0464 14.163 16.2483 17.961 11.5632 17.961ZM11.5632 3.41846C8.21671 3.41846 5.50385 6.13132 5.50385 9.47785C5.50385 12.8244 8.21671 15.5372 11.5632 15.5372C14.9098 15.5372 17.6226 12.8244 17.6226 9.47785C17.6226 6.13132 14.9097 3.41846 11.5632 3.41846Z"
              fill="#BFBFBF"
            />
            <path
              d="M1.8682 20.3847C1.54607 20.3866 1.23646 20.2601 1.00777 20.0332C0.532549 19.5619 0.529416 18.7946 1.00075 18.3194C1.00309 18.3171 1.00543 18.3147 1.00777 18.3124L5.56442 13.7557C6.05635 13.2805 6.84038 13.2941 7.31561 13.786C7.79083 14.278 7.77725 15.062 7.28532 15.5372L2.72863 20.0332C2.49994 20.2601 2.19033 20.3866 1.8682 20.3847Z"
              fill="#BFBFBF"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="19.3971" height="19.3971" fill="white" transform="translate(0.649292 0.99118)" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};
export default SvgList;
