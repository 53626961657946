import * as React from 'react';
const UploadImageSvg = (props) => (
  <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 12.2631V21.2631M12 12.2631L9.49996 14.2631M12 12.2631L14.5 14.2631M5.03396 9.38013C4.08817 9.61812 3.26184 10.1933 2.71021 10.9976C2.15859 11.8018 1.91964 12.7799 2.03827 13.7479C2.15689 14.7159 2.62492 15.6073 3.35443 16.2546C4.08393 16.9019 5.02469 17.2605 5.99996 17.2631H6.99996"
      stroke="#191D23"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8299 7.40115C15.4881 6.04772 14.6445 4.87489 13.47 4.1204C12.2956 3.36592 10.8782 3.08624 9.50517 3.33805C8.13215 3.58986 6.90625 4.35433 6.07598 5.47648C5.2457 6.59864 4.87318 7.99451 5.03392 9.38115C5.03392 9.38115 5.18692 10.2631 5.49992 10.7631"
      stroke="#191D23"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 17.2632C17.706 17.2627 18.404 17.1127 19.0479 16.823C19.6917 16.5334 20.267 16.1107 20.7357 15.5827C21.2045 15.0547 21.5561 14.4334 21.7674 13.7597C21.9787 13.086 22.045 12.3753 21.9618 11.6741C21.8786 10.973 21.6479 10.2974 21.2848 9.69192C20.9217 9.0864 20.4345 8.56463 19.8552 8.16097C19.276 7.75731 18.6178 7.4809 17.924 7.34994C17.2302 7.21899 16.5166 7.23645 15.83 7.40118L14.5 7.76318"
      stroke="#191D23"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UploadImageSvg;

