/**
 * Name: Login
 * Author: @Gaurav
 * Date: 09-21-2020
 */
import React, { useEffect } from 'react';
import './LoginStyle.scss';
import Form from './Form';
import LOGIN_IMAGE from '../../assets/login_right.jpg';
import { _showAlert } from '../../utils';
import { useLocation } from 'react-router-dom';

function Login() {
  const location = useLocation();

  useEffect(() => {
    if (location?.search) {
      if (location.search === '?authFailed=true') {
        _showAlert('Session time out please login again', 'error', 10000);
      }
    }
  }, [location]);

  return (
    <div className="container">
      <div className="main-login-wrapper">
        <div className="left-wrapper">
          <Form />
        </div>
        <div className="right-wrapper">
          <img src={LOGIN_IMAGE} />
        </div>
      </div>
    </div>
  );
}
export default Login;
