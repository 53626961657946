/**
 * Name: Edit Playlist Main saga
 * Author: @Gaurav
 * Date: 21-07-2021
 */
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';

const callAPI = (payload) => {
  return ApiCaller(`/v1/admin/playlists?search=${payload}`, 'GET');
};

export default function* watchRequest() {
  yield takeLatest('GET_ALL_PLAYLIST', function* performAction(action) {
    yield put({ type: 'GET_ALL_PLAYLIST_INITIATED' });
    try {
      const data = yield call(callAPI.bind(this, action.payload));
      yield put({ type: 'GET_ALL_PLAYLIST_SUCCESS', payload: data });
    } catch (error) {
      yield put({ type: 'GET_ALL_PLAYLIST_FAILED', payload: error });
    }
  });
}

