/**
 * Name: Delete Playlist saga
 * Author: @Gaurav
 * Date: 21-07-2021
 */
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';

const callAPI = (payload) => {
  return ApiCaller('/v1/admin/playlists', 'delete', payload);
};

export default function* watchRequest() {
  yield takeLatest('DELETE_PLAYLIST', function* performAction(action) {
    yield put({ type: 'DELETE_PLAYLIST_INITIATED' });
    try {
      const data = yield call(callAPI.bind(this, action.payload));
      yield put({ type: 'DELETE_PLAYLIST_SUCCESS', payload: data });
    } catch (error) {
      yield put({ type: 'DELETE_PLAYLIST_FAILED', payload: error });
    }
  });
}

