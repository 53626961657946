import * as React from 'react';
const EditIconSvg = (props) => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.414 15.414L21 9L9 21V27.414H15H15.414H27V25.414H17.414L27.414 15.414ZM14.586 25.414H11V21.828L21 11.828L24.586 15.414L14.586 25.414Z"
      fill="black"
    />
  </svg>
);
export default EditIconSvg;

