/**
 * Name: Search songs Reducer
 * Author: @Gaurav
 * Date: 19-07-2021
 */

export default function reducer(
  state = {
    status: 'uninitiated',
    result: {},
  },
  action
) {
  switch (action.type) {
    case 'SEARCH_SONGS_INITIATED': {
      return { ...state, status: 'initiated' };
    }
    case 'SEARCH_SONGS_SUCCESS': {
      return { ...state, status: 'success', result: action.payload };
    }
    case 'SEARCH_SONGS_FAILED': {
      return { ...state, status: 'failed', result: action.payload };
    }
    default: {
      return state;
    }
  }
}
