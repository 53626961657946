/**
 * Name: ProtectedRoutes
 * Author: @Gaurav
 * Date: 09-21-2020
 */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { _isAuthenticated } from '../utils';

const ProtectedRoutes = ({ routeType = [], component: Component, ...rest }) => {
  const { tokens } = _isAuthenticated();

  /**
   * check the routes authenticity
   * @param props
   */
  const _getRoute = (props) => {
    if (tokens) {
      return <Component {...props} />;
    } else {
      return <Redirect to="/" />;
    }
  };

  return <Route {...rest} render={(props) => _getRoute(props)} />;
};

export default ProtectedRoutes;
