/**
 * Name: Edit Customer Idsaga
 * Author: @Gaurav
 * Date: 22-07-2021
 */
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';

const callAPI = (payload) => {
  return ApiCaller(`/v1/admin/playlists/customer/${payload.customerId}`, 'POST', { playlists: payload.playlists });
};

export default function* watchRequest() {
  yield takeLatest('ADD_CUSTOMER_PLAYLIST', function* performAction(action) {
    yield put({ type: 'ADD_CUSTOMER_PLAYLIST_INITIATED' });
    try {
      const data = yield call(callAPI.bind(this, action.payload));
      yield put({ type: 'ADD_CUSTOMER_PLAYLIST_SUCCESS', payload: data });
    } catch (error) {
      yield put({ type: 'ADD_CUSTOMER_PLAYLIST_FAILED', payload: error });
    }
  });
}

