/**
 * Name: DftButton
 * Author: @Gaurav
 * Date: 15-07-2021
 */
import React from 'react';
import './Button.scss';

const DftButton = (props) => {
  const { onClick } = props;
  return (
    <button onClick={onClick} className="dftButton">
      {props.button}
    </button>
  );
};

export default DftButton;
