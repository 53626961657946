export const diffArray = (arr1, arr2) => {
  var ret = [];
  arr1.sort();
  arr2.sort();
  for (var i = 0; i < arr1.length; i += 1) {
    if (arr2.indexOf(arr1[i]) > -1) {
      ret.push(arr1[i]);
    }
  }
  return ret;
};

export const getShortNamesOfWeekDays = (arrayOfDays) => {
  const WEEK_DAYS_SHORTEST_FORMAT = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  return arrayOfDays
    .sort()
    .map((item) => WEEK_DAYS_SHORTEST_FORMAT[item])
    .join(',');
};

export const getDaysNameFromArrayIndex = (arrayOfDays) => {
  const WEEK_DAYS_SHORTEST_FORMAT = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return arrayOfDays
    .sort()
    .map((item) => WEEK_DAYS_SHORTEST_FORMAT[item])
    .join(',');
};

export const getWeekDays = () => {
  return [
    { name: 'Sun', value: 0, label: 'Sunday' },
    { name: 'Mon', value: 1, label: 'Monday' },
    { name: 'Tue', value: 2, label: 'Tuesday' },
    { name: 'Wed', value: 3, label: 'Wednesday' },
    { name: 'Thu', value: 4, label: 'Thursday' },
    { name: 'Fri', value: 5, label: 'Friday' },
    { name: 'Sat', value: 6, label: 'Saturday' },
  ];
};

export const getSchedulesFromPlaylist = (list) => {
  let scheduleList = [];
  if (Array.isArray(list)) {
    for (let i = 0; i < list.length; i++) {
      let listItem = list[i];
      if (Array.isArray(listItem.schedule)) {
        let updatedSchedulesItems = listItem.schedule.map((item) => ({ ...item, playlistId: listItem.id, name: listItem.name }));
        scheduleList = [...scheduleList, ...updatedSchedulesItems];
      }
    }
  }
  return scheduleList;
};
