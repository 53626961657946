import React, { useState } from 'react';

import '../CustomerList/CustomerList.scss';
import EditIconSvg from '../../components/Svgs/EditIcon.svg';
import BlockIconSvg from '../../components/Svgs/BlockIcon.svg';
import InfoIconSvg from '../../components/Svgs/InfoIcon.svg';
import ActivityIconSvg from '../../components/Svgs/ActivityIcon.svg';
import ShuffleIconSvg from '../../components/Svgs/ShuffleIcon.svg';
import RefreshIconSvg from '../../components/Svgs/RefreshIcon.svg';
import DeleteIconSvg from '../../components/Svgs/DeleteIcon.svg';

const MoreInfoPopUp = ({
  setIsActionPopUpOpen,
  xPos,
  yPos,
  handleCustomerEditPage,
  handleCustomerDetails,
  setBlockUserModalOpen,
  setLogsModalOpen,
  handleRefreshClick,
  onDeleteIconClick,
  handleShuffle,
}) => {
  return (
    <>
      <div className="backDropContainer" onClick={() => setIsActionPopUpOpen(false)} />
      <div style={{ transform: `translateX(${xPos - 180}px) translateY(${yPos}px)` }} className="pop_up_container">
        <ul>
          <li onClick={handleCustomerEditPage}>
            <EditIconSvg />
            <p>Edit</p>
          </li>
          <li
            onClick={() => {
              setIsActionPopUpOpen(false);
              setBlockUserModalOpen(true);
            }}
          >
            <BlockIconSvg />
            <p>Block/Open</p>
          </li>
          <li onClick={handleCustomerDetails}>
            <InfoIconSvg />
            <p>Info</p>
          </li>
          <li
            onClick={() => {
              setIsActionPopUpOpen(false);
              setLogsModalOpen(true);
            }}
          >
            <ActivityIconSvg />
            <p>Activity</p>
          </li>
          <li onClick={handleShuffle}>
            <ShuffleIconSvg />
            <p>Shuffle</p>
          </li>
          <li onClick={handleRefreshClick}>
            <RefreshIconSvg />
            <p>Refresh</p>
          </li>
          <li onClick={onDeleteIconClick}>
            <DeleteIconSvg />
            <p style={{ color: '#C04444' }}>Delete</p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MoreInfoPopUp;

