import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function LoaderSpinner(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress size={60} variant="determinate" className={classes.bottom} thickness={4} {...props} value={100} />
      <CircularProgress
        size={60}
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function CustomizedLoaderSpinner() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoaderSpinner />
    </div>
  );
}
