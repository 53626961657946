import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

import { _showAlert } from '../../utils';
import ApiCaller from '../../redux/ApiCaller';

import '../CustomerList/CustomerList.scss';

const DeleteCustomerPopup = ({ selectedCustomer, setIsDeletePopUpVisible }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { firstname, lastname } = selectedCustomer || {};
  const customerName = firstname + ' ' + lastname;

  const deleteCustomer = async () => {
    try {
      setIsLoading(true);
      const payload = {
        ids: [selectedCustomer.id],
      };
      await ApiCaller(`/v1/admin/customers`, 'DELETE', payload);
      setIsLoading(false);
      dispatch({ type: 'CUSTOMER_LIST', payload: '' });
      setIsDeletePopUpVisible(false);
      _showAlert('customer deleted successfully!!', 'sucess');
    } catch (error) {
      setIsLoading(true);
      _showAlert(error.message, 'error');
    }
  };

  return (
    <div className="modalBackDrop" style={{ zIndex: 10 }} onClick={() => setIsDeletePopUpVisible(false)}>
      <div className="blockUserModal" style={{ height: 'auto' }} onClick={(e) => e.stopPropagation()}>
        <p
          className="label"
          style={{ marginTop: '30px', marginBottom: '10px', width: '90%' }}
        >{`Are you sure you want to delete ${customerName}? `}</p>
        <div className="btnContainer">
          {isLoading ? (
            <div style={{ marginRight: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Button variant="outlined" color="error" onClick={deleteCustomer}>
                Yes
              </Button>
              <Button variant="outlined" onClick={() => setIsDeletePopUpVisible(false)}>
                No
              </Button>{' '}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteCustomerPopup;

