/**
 * Name: Edit Playlist
 * Author: @Gaurav
 * Date: 20-07-2021
 */
import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import search from '../../assets/ic_search.png';
import RoundButton from '../../components/RoundButton';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PlayBtn from '../../assets/play_btn.svg';
import Delete from '../../assets/Delete.png';
import { _showAlert } from '../../utils';
import AlertModal from '../../components/Modal';
import PauseIconSvg from '../../components/Svgs/PauseIcon.svg';
import { CircularProgress } from '@material-ui/core';

/* 
 Table Data and Use Style
 */
const useStyles = makeStyles({
  table: {
    minWidth: 372,
    borderRadius: 30,
  },
});

function EditPlaylist({
  playlistToUpdate,
  onPlaylistTabClick,
  onUpdatePlaylist,
  selectedSongsToUpload,
  handleSelectSongsToUpload,
  handleMultipleSongsToUpload,
  handleRemoveSong,
  handleSearchInput,
  searchInput,
  allPlaylist,
  handleSelectPlaylist,
  selectedPlaylist,
  handlePlaySong,
  isSongLoading,
  isSongPlaying,
  currentPlayingSong,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [deleteSelectedPlaylist, setDeleteSelectedPlaylist] = useState(null);
  const [SelectedPlayBtn, setSelectedPlayBtn] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Close Modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  const filterAllOtherThanSelected = (playList) => {
    if (Array.isArray(playList) && playList.length) {
      return playList.filter((item) => item.id !== playlistToUpdate.id);
    }
    return [];
  };

  const getPlaylistSongsList = () => {
    if (Array.isArray(selectedPlaylist)) {
      let songsList = [];
      selectedPlaylist.map((item) => {
        songsList = [...songsList, ...item.songs];
      });
      return songsList;
    }
    return [];
  };

  const isAllSongSelected = () => {
    const currentPlaylistSongs = getPlaylistSongsList();

    if (!currentPlaylistSongs.length) return false;

    // check if each song is selected
    return currentPlaylistSongs.every((song) => selectedSongsToUpload.some((selectedSong) => selectedSong.id === song.id));
  };

  const handleRemoveSongLocal = (deleteSelectedPlaylist) => {
    handleRemoveSong(deleteSelectedPlaylist);
    setOpen(false);
  };
  const handleSelectedPlayBtn = (row) => {
    setIsPlaying(isSongPlaying && currentPlayingSong.id === row.id);
    setIsLoading(isSongLoading && currentPlayingSong.id === row.id);
    handlePlaySong(row);
    setSelectedPlayBtn(row);
  };

  useEffect(() => {
    setIsLoading(isSongLoading);
  }, [isSongLoading]);

  return (
    <div className="main">
      <div className="sidebar">
        <Sidebar />
      </div>
      <AlertModal open={open} onClose={handleClose} handelDelete={() => handleRemoveSongLocal(deleteSelectedPlaylist)} />
      <div className="leftPortion">
        <NavBar data="Edit Playlist" icon={Settings} currentRouteName="Playlist" breadcrumbsPath="Edit Playlist / Playlist" />
        <div className="content">
          <div className="warp_edit_row">
            <div className="editRow">
              <div className="wrap_edit">
                <p>Edit:</p>
                <RoundButton btnText="Playlist" />
                <RoundButton className="disabled-button" handleClick={() => onPlaylistTabClick('playlistServer')} btnText="server" />
              </div>

              <div className="search_row wdth">
                <input
                  className="input_field"
                  placeholder="Search something here..."
                  type="text"
                  value={searchInput}
                  onChange={(e) => handleSearchInput(e.target.value)}
                />
                <img src={search} />
              </div>
            </div>
            <div className="">
              <RoundButton btnText="Save" handleClick={onUpdatePlaylist} />
            </div>
          </div>

          <div className="main_tbl_wrap">
            <TableContainer
              className="tbl_wraper tbl_wrap1"
              style={{ border: '1px solid #40189D', borderRight: 'none', borderRadius: 0 }}
              component={Paper}
            >
              <Table style={{ borderTopRightRadius: 0 }} className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox disabled inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                    </TableCell>
                    <TableCell className="table_font">Result</TableCell>
                    <TableCell>Playlist Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterAllOtherThanSelected(allPlaylist).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedPlaylist.some((item) => item.id === row.id) || false}
                          onChange={(event) => handleSelectPlaylist(event, row)}
                          inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                        />
                      </TableCell>
                      <TableCell className="table-index-cell">#{++index}</TableCell>
                      <TableCell className="table-name-cell">{row.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box display="flex" height="100%" alignItems="center">
              <div className="divider-line" />
            </Box> */}
            <TableContainer className="tbl_wraper tbl_wrap2" style={{ border: '1px solid #40189D' }} component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                        onChange={(event) => handleMultipleSongsToUpload(event, getPlaylistSongsList())}
                        checked={isAllSongSelected()}
                      />
                    </TableCell>
                    <TableCell className="table_font">Result</TableCell>
                    <TableCell>Song Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPlaylistSongsList()?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          onChange={(event) => handleSelectSongsToUpload(event, row)}
                          inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                          checked={selectedSongsToUpload.some((item) => item.id === row.id) || false}
                          name="song"
                        />
                      </TableCell>
                      <TableCell className="table-index-cell">#{++index}</TableCell>
                      <TableCell className="table-name-cell">{row.fileName || row.name || 'N/A'}</TableCell>
                      <TableCell>
                        {/* <span
                          className={SelectedPlayBtn && SelectedPlayBtn.id == row.id ? 'active_play_btn' : 'null'}
                          onClick={() => handleSelectedPlayBtn(row)}
                        >
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34873 18.9426 4.80688 17.0679 2.93215C15.1931 1.05742 12.6513 0.0029116 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9976 12.121 17.154 14.1544 15.6542 15.6542C14.1545 17.154 12.121 17.9976 10 18Z"
                              fill="#40189D"
                            />
                            <path d="M7 4.446V15.554L15.887 10L7 4.446ZM9 8.054L12.113 10L9 11.946V8.054Z" fill="#40189D" />
                          </svg>
                        </span> */}
                        {isLoading && row?.id === currentPlayingSong?.id ? (
                          <CircularProgress />
                        ) : (
                          <>
                            {!isPlaying && row?.id === currentPlayingSong?.id ? (
                              <PauseIconSvg onClick={() => handleSelectedPlayBtn(row)} />
                            ) : (
                              <img src={PlayBtn} onClick={() => handleSelectedPlayBtn(row)} />
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer className="tbl_wraper  selectTbl" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table_font">Result</TableCell>
                    <TableCell>Selected Songs</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(selectedSongsToUpload) &&
                    selectedSongsToUpload.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="table-index-cell">#{++index}</TableCell>
                        <TableCell className="table-name-cell">{row.title || row.fileName || row.name || 'N/A'}</TableCell>
                        <TableCell>
                          <img
                            className="remove-icon"
                            src={Delete}
                            onClick={() => {
                              setOpen(true);
                              setDeleteSelectedPlaylist(row);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditPlaylist;

