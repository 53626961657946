/**
 * Name: Login
 * Author: @Gaurav
 * Date: 19-07-2021
 */
import React, { useEffect, useState } from 'react';
import './LoginStyle.scss';
import UPPER_LOGIN from './../../assets/upperLogin-image.png';
import USER_ICN from './../../assets/user_icn.svg';
import EYE_ICN from './../../assets/eye_icn.svg';
import HIDE_EYE from './../../assets/hide-private-hidden.svg';
import PASSWORD_ICN from './../../assets/password_icn.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { _showAlert } from '../../utils';

const Form = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorUsername, setErrorUserName] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [readoOnlyFields, setReadOnlyFields] = useState({
    username: true,
    password: true,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const loginRequestData = useSelector((state) => state.login.result);
  /**
   * it will handle the login api request and submit validation
   */
  const handleSubmitLogin = () => {
    if (username === '') {
      setErrorUserName('Please fill your Username');
    } else if (password === '') {
      setErrorPassword('Please fill your Password');
    } else if (password.length < 8) {
      setErrorPassword('Password must be 8 character long');
    } else {
      dispatch({ type: 'LOGIN_USER', payload: { username: username, password: password } });
    }
  };

  /**
 It will handle Validation on Input Field
 */

  const handleChange = (e, text) => {
    if (text === 'username') {
      setUserName(e.target.value);
      setErrorUserName('');
    } else if (text === 'password') {
      setPassword(e.target.value);
      setErrorPassword('');
    }
  };

  useEffect(() => {
    if (loginRequestData && loginRequestData.status == 1) {
      _showAlert('Login Successfully', 'success', 4000);
      const expirationtime = new Date().getTime() + 9 * 60000;
      sessionStorage.setItem('authData', JSON.stringify(loginRequestData));
      sessionStorage.setItem('expirationtime', expirationtime);
      setTimeout(() => history.push('/customer-list'), 500);
    }
    if (loginRequestData && loginRequestData.status == 2) {
      _showAlert('Wrong email or Password', 'error', 10000);
    }
  }, [loginRequestData]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'LOGIN_USER' });
    };
  }, []);

  return (
    <div className="wrap_content">
      <div className="login_logo">
        <img src={UPPER_LOGIN} />
      </div>
      <h2>Login to 4Play admin</h2>
      <p className="fnt13">Enter to continue and explore within your grasp</p>
      <form className="login_form">
        <div className="input-main-warpper">
          <div className="wrap_field">
            <input
              placeholder="User Name"
              className="login_field"
              value={username}
              onChange={(e) => handleChange(e, 'username')}
              required
              readOnly={readoOnlyFields.username}
              onFocus={() => setReadOnlyFields({ ...readoOnlyFields, username: false })}
              name="username"
            />
            <img src={USER_ICN} />
          </div>
          <label className="input-error-message">{errorUsername}</label>
        </div>
        <div className="input-main-warpper">
          <div className="wrap_field">
            <input
              placeholder="Password"
              className="login_field"
              type={isRevealPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => handleChange(e, 'password')}
              readOnly={readoOnlyFields.password}
              onFocus={() => setReadOnlyFields({ ...readoOnlyFields, password: false })}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  console.log('Password enter press here! ');
                  handleSubmitLogin();
                }
              }}
            />
            <img src={PASSWORD_ICN} />
            <img
              className="open_eye"
              title={isRevealPassword ? 'Hide password' : 'Show password'}
              src={isRevealPassword ? EYE_ICN : HIDE_EYE}
              onClick={() => setIsRevealPassword(!isRevealPassword)}
            />
          </div>
          <label className="input-error-message">{errorPassword}</label>
        </div>
        <button type="button" onClick={handleSubmitLogin} className="login_btn">
          Log in to continue
        </button>
      </form>
    </div>
  );
};
export default Form;

