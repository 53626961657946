/**
 * Name: Main Route Index
 * Author: @Gaurav
 * Date: 09-21-2020
 */
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import ProtectedRoutes from './ProtectedRoutes';
import NotFound from '../pages/NotFound';
import PublicRoutes from './PublicRoutes';
import { ROUTES_DATA } from './RouteData';
import './Alertify.scss';

const Routes = () => {
  return (
    <BrowserRouter>
      <section className="main-wrapper">
        <div id="alert-box-warpper" className="alert-wrapper-closed alert-main-wrapper">
          <Alert severity="">''</Alert>
        </div>
        <Switch>
          {ROUTES_DATA.map((route, index) => {
            const { isProtected } = route;
            return isProtected ? <ProtectedRoutes {...route} key={index} /> : <PublicRoutes {...route} key={index} />;
          })}
          <Route path="*" component={NotFound} />
        </Switch>
      </section>
    </BrowserRouter>
  );
};

export default Routes;
